<template>
  <div id="app" >
    <div class="bg-img" :style="bgImgStyle"></div>
    <router-view />
  </div>
</template>

<script>
import bk from "@/assets/images/formbackground.png";
export default {
  name: 'App',
  data(){
    return {
    }
  },
  computed:{
    bkUrl(){
      let vm = this;
      let rootBk = this.$root.backgroundImage;
      return rootBk||bk
    },
    bgImgStyle(){
      let vm =this;
      let s = {...vm.$root.backgroundStyle};
      return {
        'background-image':'url('+vm.bkUrl+')',
        position:s.position,
        opacity:s.opacity
      }
    }
  },
  components: {
  },
  mounted(){

  },
  methods:{

  }
}
</script>

<style lang="scss">
@import "@/assets/scss/index.scss";
</style>
