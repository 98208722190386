import Vue from 'vue'
import App from './App.vue'

import router from './router'
import axios from "@/plugins/axios";

// import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import { Button,Input, InputNumber,Icon } from 'view-design';
Vue.component('Button', Button);
Vue.component('Input', Input);
Vue.component('InputNumber', InputNumber);
Vue.component('Icon', Icon);
Vue.config.ignoredElements = ['wx-open-launch-weapp'];

import { 
  Cell,
  Dialog,
  Switch,
  Calendar,
  DatePicker,
  Picker,
  Rate,
  ImagePicker,
  Radio,
  RadioGroup,
  CheckBox,
  CheckBoxGroup,
  Address,
  TextInput,
  TextBox,
  Signature,
  LuckDraw,
  Notify
} from '@nutui/nutui';
Cell.install(Vue);
Dialog.install(Vue);
Switch.install(Vue);
Calendar.install(Vue);
DatePicker.install(Vue);
Picker.install(Vue);
Rate.install(Vue);
ImagePicker.install(Vue);
Radio.install(Vue);
RadioGroup.install(Vue);
CheckBox.install(Vue);
CheckBoxGroup.install(Vue);
Address.install(Vue);
TextInput.install(Vue);
TextBox.install(Vue);
Signature.install(Vue);
LuckDraw.install(Vue);
Notify.install(Vue);

Vue.config.productionTip = false
Vue.prototype.$http = axios;

new Vue({
  data(){
    return {
      backgroundImage:null,
      backgroundStyle:{}
    }
  },
  strict: false,
  router,
  render: h => h(App),
}).$mount('#app')
