import Vue from 'vue'
import axios from "axios";
import { Toast  } from "@nutui/nutui";
Toast.install(Vue);
import {EncryptParams,pawDecrypt} from "@/utils/sm.js";


// 超时时间
axios.defaults.timeout = 60000 * 120
// 跨域请求，允许保存cookie
axios.defaults.withCredentials = true
axios.defaults.headers = { 'Content-Type': 'application/json; charset=utf-8' }
const BASE_URL = process.env.NODE_ENV !== 'prod'? 'https://www.changliuyun.com/api' : "https://www.changliuyun.com/api"
// 对面暴露的基础请求路径
axios.BASE_URL = BASE_URL

// let loading
// 正在请求个数
axios.interceptors.request.use(
  config => {
    if (config.isFormData) {
      config.headers["Content-Type"] = "multipart/form-data"
    }
    if (!config.rest && config.headers.upload != 'true') {
      config.url = BASE_URL + config.url
    } else {
      delete config.withCredentials;
    }
    let type = config.method
    if (type === 'post') {
      if(config.isFormData){
        //如果是上传文件，那么不加密
        config.data = config.data;
      }else{
        if(!config.rest){
          //统一使用sm2加密
          let text = EncryptParams(JSON.stringify(config.data));
          let nbody = {
            params:text
          }
          config.data = JSON.stringify(nbody);
        }
      }
    } else if (type === 'get') {
      // get请求参数处理
      config.params = merge(defaults, config.params)
    }
    // Do something before request is sent
    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  response => {
    console.log(response);
    let realdata = JSON.parse(pawDecrypt(response.data.data.params));
    let nresult = {
      msg:response.data.msg,
      code:response.data.code,
      data:realdata
    }
    if (!response.config.notip) {
      let code = nresult.code;
      let msg = nresult.msg;
      console.log(code);
      if(code==0||code=="0000"||response.data.success){
        Toast.success("操作成功");
      }else{
        Toast.warn(msg)
      }
      // Message({
      //   message: code=="0"||code=="0000"||response.data.success?"操作成功":msg,
      //   type: code=="0"||code=="0000"||response.data.success?"success":'error',
      //   showClose: true,
      //   duration: 1500
      // })
    }
    // Do something with response data
    return nresult;
  },
  error => {
    if (typeof error == "object" && error.response) {
      let msg = error.response.data?error.response.data.result:error.response.error;
      if(msg){
        Toast.warn(msg)
      }
    }
    return Promise.reject(error);
  }
);

export default axios;
