import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const mainRoutes = [{
    path: '/',
    name: 'form',
    meta: {
      title: "主页",
    },
    component: () => import(/* webpackChunkName: 'main' */ './views/main'),
    children: [
      {
        path: "pcauth",
        name: "pcauth",
        meta: {
          title: "微信授权",
        },
        component: () => import(/* webpackChunkName: 'pcauth' */ './views/pcauth'),
      },
      {
        path: "upgradevip",
        name: "upgradevip",
        meta: {
          title: "升级会员",
        },
        component: () => import(/* webpackChunkName: 'pcauth' */ './views/vip'),
      },
      {
        path: "preview",
        name: "preview",
        meta: {
          title: "表单预览",
        },
        component: () => import(/* webpackChunkName: 'preview' */ './views/preview'),
      },
      {
        path: "wxauth",
        name: "wxauth",
        meta: {
          title: "表单预览",
        },
        component: () => import(/* webpackChunkName: 'wxauth' */ './views/wxauth'),
      },
      {
        path:"public/:id",
        name:"public",
        meta:{
        },
        component:()=> import(/* webpackChunkName: 'public' */ './views/public')
      }
    ],
    beforeEnter(to, from, next) {
      next()
    }
}]
  
  
  
const router = new Router({
    mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    base: process.env.BASE_URL,
    routes: mainRoutes
})
  
  
export default router;
  