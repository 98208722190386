import {sm2} from 'sm-crypto'

export function setLS(e, t) {
    window.localStorage.setItem(e, JSON.stringify(t))
}
export function getLS(e) {
    return JSON.parse(window.localStorage.getItem(e))
}
export function cleanLS(e) {
    window.localStorage.removeItem(e)
}
export function jsonParse(e) {
    return e = e.replace(/[\n]/g, "\\n").replace(/[\r]/g, "\\r"),
    JSON.parse(e)
}
export function generateGuid() {
    var e = function() {
        return (65536 * (1 + Math.random()) | 0).toString(16).substring(1)
    };
    return e() + e() + e() + e() + e() + e() + e() + e()
}

export function Encrypt(e) {
    if ("" === e)
        return null;
    e = escape(e);
    var t = "ae8b516cbffde62a74b726c82eb6748ad41d251480d84ce2e96ebf57ebaa8b22";
    if (null === (t = escape(t)) || t.length <= 0)
        return alert("Please enter a password with which to encrypt the message."),
        null;
    for (var n = "", a = 0; a < t.length; a++)
        n += t.charCodeAt(a).toString();
    var i = Math.floor(n.length / 5)
      , r = parseInt(n.charAt(i) + n.charAt(2 * i) + n.charAt(3 * i) + n.charAt(4 * i) + n.charAt(5 * i))
      , s = Math.ceil(t.length / 2)
      , o = Math.pow(2, 31) - 1;
    if (r < 2)
        return alert("Algorithm cannot find a suitable hash. Please choose a different password. /nPossible considerations are to choose a more complex or longer password."),
        null;
    var l = Math.round(1e9 * Math.random()) % 1e8;
    for (n += l; n.length > 10; )
        n = (parseInt(n.substring(0, 10)) + parseInt(n.substring(10, n.length))).toString();
    n = (r * n + s) % o;
    for (var c = "", u = "", p = 0; p < e.length; p++)
        u += (c = parseInt(e.charCodeAt(p) ^ Math.floor(n / o * 255))) < 16 ? "0" + c.toString(16) : c.toString(16),
        n = (r * n + s) % o;
    for (l = l.toString(16); l.length < 8; )
        l = "0" + l;
    return u += l
}

export function Decrypt(e) {
    if ("" === e)
        return null;
    var t = "ae8b516cbffde62a74b726c82eb6748ad41d251480d84ce2e96ebf57ebaa8b22";
    if (t = escape(t),
    null === e || e.length < 8)
        return alert("A salt value could not be extracted from the encrypted message because it's length is too short. The message cannot be decrypted."),
        null;
    if (null === t || t.length <= 0)
        return alert("Please enter a password with which to decrypt the message."),
        null;
    for (var n = "", a = 0; a < t.length; a++)
        n += t.charCodeAt(a).toString();
    var i = Math.floor(n.length / 5)
      , r = parseInt(n.charAt(i) + n.charAt(2 * i) + n.charAt(3 * i) + n.charAt(4 * i) + n.charAt(5 * i))
      , s = Math.round(t.length / 2)
      , o = Math.pow(2, 31) - 1
      , l = parseInt(e.substring(e.length - 8, e.length), 16);
    for (e = e.substring(0, e.length - 8),
    n += l; n.length > 10; )
        n = (parseInt(n.substring(0, 10)) + parseInt(n.substring(10, n.length))).toString();
    n = (r * n + s) % o;
    for (var c = "", u = "", p = 0; p < e.length; p += 2)
        c = parseInt(parseInt(e.substring(p, p + 2), 16) ^ Math.floor(n / o * 255)),
        u += String.fromCharCode(c),
        n = (r * n + s) % o;
    return unescape(u)
}

export function pawDecrypt(e){
    if ("" === e)
        return null;
    e = e.substring(2);
    return sm2.doDecrypt(e, "00ac05b732e93224a9dbdf5e59c3fbb513d8e3c218108f762f4aff2f0337243f3e", 1)
}

export function EncryptParams(e){
    console.log(sm2);
    if ("" === e)
        return null;
    return "04" + sm2.doEncrypt(e, "04a72d8110d22e65e91614c8f9ab8f3f1072bdc86b6f92651d015dd2d9ec98e369df3c42af88da34f0c5dbe9eafbeee69c10adb844d95150500a83ae4e940830e5", 1)
            
}

export function urlEncrypt(e) {
    if (void 0 === e || null === e || "#" === e || "" === e)
        return e;
    var t = e.indexOf("?", "0");
    if (-1 === t)
        return e;
    var n = e.substring(t + 1, e.length)
      , a = new URLSearchParams(n)
      , i = {}
      , r = !0
      , o = !1
      , c = void 0;
    try {
        for (var u, p = s()(a); !(r = (u = p.next()).done); r = !0) {
            var h = u.value;
            2 === h.length && (i[h[0]] = h[1])
        }
    } catch (e) {
        o = !0,
        c = e
    } finally {
        try {
            !r && p.return && p.return()
        } finally {
            if (o)
                throw c
        }
    }
    return e.substring(0, t + 1) + "params=" + EncryptParams(l()(i))
}